var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(_vm._s(_vm.$t("latest-will-statistics")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "col-6 justify-content-center"
  }, [_c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": "example-date-input"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("start-date")))]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.date.start,
      expression: "date.start"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date",
      "id": "example-date-input"
    },
    domProps: {
      "value": _vm.date.start
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.date, "start", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": "example-date-input"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("last-date")))]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.date.end,
      expression: "date.end"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date",
      "id": "example-date-input"
    },
    domProps: {
      "value": _vm.date.end
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.date, "end", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "d-flex justify-content-end m-3"
  }, [_c('b-button', {
    staticClass: "btn",
    on: {
      "click": _vm.search
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("search")) + " ")])], 1), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm.loaded ? _c('Graph', {
    attrs: {
      "data": this.data,
      "options": this.options,
      "date": this.date
    }
  }) : _vm._e()], 1)])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }